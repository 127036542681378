@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.footerContainer {
  background-color: colors.$primary-color-dark;
  color: colors.$white;
  margin-top: auto;
  padding: 30px;
  contain: content;

  @media screen and (min-width: vars.$large) {
    padding: 30px;
  }
}

.copyrights {
  text-align: center;
}
